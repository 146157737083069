<template>
    <div class="section_wpr mb-2">
        <div class="edit_section">
            <sending-method v-model="notif_method" />
        </div>
    </div>
    <div v-if="notif_method === 1 || notif_method === 3">
        <label for="email_alert" class="switch_option capsule_btn m-0">
            <h5>Email</h5>
            <input type="checkbox" id="email_alert" v-model="emailAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr mb-4" v-if="emailAlert">
            <email-component v-model="emailContent" :errors="errors" :handle-default-email="handleDefaultEmail" ref="email-component" />
        </div>
    </div>
    <div v-if="notif_method === 2 || notif_method === 3">
        <label for="sms_alert" class="switch_option capsule_btn">
            <h5>SMS Setup</h5>
            <input type="checkbox" id="sms_alert" v-model="smsAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr" v-if="smsAlert">
            <sms-component v-model="delivery_sms" media-field-name="sms_media" :media="sms_media" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" ref="sms-component" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Reward Notification Setting',

        data(){
            return{
                emailAlert: false,
                smsAlert: true,
                notif_method: 1,
                delivery_sms: '',
                emailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                sms_media: [],
            }
        },

        props: {
            errors: Object,
            modelValue: Object,
            type: String,
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            resetForm () {
                const vm = this;

                if (vm.type == 'awardedForm') {
                    var message  = 'Hi {{first_name}}\n';
                        message += '\n';
                        message += 'You just earned {{points_awarded}} {{points_name}} for {{reward_reason}}!\n';
                        message += '\n';
                        message += 'You now have {{total_points}} {{points_name}} in your account to redeem for cash value toward any program at {{company_name}}.\n';
                        message += '\n';
                        message += 'Call us at {{company_phone}} to get started.\n';
                        message += 'Team {{company_name}}';
                    var content  = `<p>Hi {{first_name}},{{last_name}}</p>
                                    <p>You just earned {{points_awarded}} {{points_name}} for {{reward_reason}}!</p>
                                    <p>You now have {{total_points}} {{points_name}} in your account you may redeem for cash value toward any program at {{company_name}}.</p>
                                    <p>Call us at {{company_phone}} to get started.</p>
                                    <p>Team {{company_name}}</p>`;

                    vm.notif_method = vm.modelValue.awarded_notification ? parseInt(vm.modelValue.awarded_notification) : 3;
                    vm.delivery_sms = vm.modelValue.awarded_sms ? vm.modelValue.awarded_sms : message;
                    vm.emailContent = {
                        defaultHandler: true,
                        subject: vm.modelValue.awarded_subject ? vm.modelValue.awarded_subject : 'You Got Points!',
                        email: vm.modelValue.awarded_email ? vm.modelValue.awarded_email : content,
                        show_email_logo: vm.modelValue.reward_show_email_logo ? vm.modelValue.reward_show_email_logo : 0,
                        email_logo: vm.modelValue.reward_email_logo ? vm.modelValue.reward_email_logo : '',
                        is_email_logo: vm.modelValue.is_reward_logo ? vm.modelValue.is_reward_logo : 0,
                        is_signature: vm.modelValue.is_reward_signature ? vm.modelValue.is_reward_signature : 0,
                        signature_id: vm.modelValue.reward_signature_id ? vm.modelValue.reward_signature_id : 0,
                    };
                }

                if (vm.type == 'redeemedForm') {
                    var message  = 'Hi {{first_name}}\n';
                        message += '\n';
                        message += 'You have redeemed {{points_redeemed}} {{points_name}} for {{redemption_reason}}\n';
                        message += '\n';
                        message += 'Your new {{points_name}} balance is {{total_points}}. Thanks for training with us!\n';
                        message += '\n';
                        message += 'Team {{company_name}}';
                    var content  = `<p>Hi {{first_name}},</p>
                                    <p>You have redeemed {{points_redeemed}} {{points_name}} for {{redemption_reason}}.</p>
                                    <p>Your remaining points balance is {{total_points}}. Thanks for training with us!</p>
                                    <p>Team {{company_name}}</p>
                                    <p>{{company_phone}}</p>`;

                    vm.notif_method = vm.modelValue.redeemed_notification ? parseInt(vm.modelValue.redeemed_notification) : 3;
                    vm.delivery_sms = vm.modelValue.redeemed_sms ? vm.modelValue.redeemed_sms : message;
                    vm.emailContent = {
                        defaultHandler: true,
                        subject: vm.modelValue.redeemed_subject ? vm.modelValue.redeemed_subject : `You've Used Points {{first_name}}!`,
                        email: vm.modelValue.redeemed_email ? vm.modelValue.redeemed_email : content,
                        show_email_logo: vm.modelValue.reward_show_email_logo ? vm.modelValue.reward_show_email_logo : 0,
                        email_logo: vm.modelValue.redeemed_email_logo ? vm.modelValue.redeemed_email_logo : '',
                        is_email_logo: vm.modelValue.is_redeemed_logo ? vm.modelValue.redeemed_show_email_logo : 0,
                        is_signature: vm.modelValue.is_redeemed_signature ? vm.modelValue.is_redeemed_signature : 0,
                        signature_id: vm.modelValue.redeemed_signature_id ? vm.modelValue.redeemed_signature_id : 0,
                    };
                }
                vm.emailAlert = true;

                vm.sms_media = vm.modelValue.sms_media;
            },

            handleDefaultEmail () {
                const vm = this;

                if (vm.type == 'awardedForm') {
                    vm.emailContent  = {
                        defaultEmail: true,
                        subject: 'You Got Points!',
                        email: `<p>Hi {{first_name}},{{last_name}}</p>
                                <p>You just earned {{points_awarded}} {{points_name}} for {{reward_reason}}!</p>
                                <p>You now have {{total_points}} {{points_name}} in your account you may redeem for cash value toward any program at {{company_name}}.</p>
                                <p>Call us at {{company_phone}} to get started.</p>
                                <p>Team {{company_name}}</p>`,
                    }
                }

                if (vm.type == 'redeemedForm') {
                    vm.emailContent  = {
                        defaultEmail: true,
                        subject: `You've Used Points {{first_name}}!`,
                        email: `<p>Hi {{first_name}},</p>
                                <p>You have redeemed {{points_redeemed}} {{points_name}} for {{redemption_reason}}.</p>
                                <p>Your remaining points balance is {{total_points}}. Thanks for training with us!</p>
                                <p>Team {{company_name}}</p>
                                <p>{{company_phone}}</p>`,
                    }
                }
            },

            handleDefaultSms () {
                const vm = this;

                if (vm.type == 'awardedForm') {
                    vm.delivery_sms  = 'Hi {{first_name}}\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'You just earned {{points_awarded}} {{points_name}} for {{reward_reason}}!\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'You now have {{total_points}} {{points_name}} in your account to redeem for cash value toward any program at {{company_name}}.\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'Call us at {{company_phone}} to get started.\n';
                    vm.delivery_sms += 'Team {{company_name}}';
                }

                if (vm.type == 'redeemedForm') {
                    vm.delivery_sms  = 'Hi {{first_name}}\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'You have redeemed {{points_redeemed}} {{points_name}} for {{redemption_reason}}\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'Your new {{points_name}} balance is {{total_points}}. Thanks for training with us!\n';
                    vm.delivery_sms += '\n';
                    vm.delivery_sms += 'Team {{company_name}}';
                }
            }
        }
    }
</script>
